import { useNavigate } from '@gimlite/router';
import {
  navigationService,
  translationService,
  useRootStore,
  wizardService,
} from '@gimlite/watermelon';
import IEM from '@gimlite/watermelon/assets/logo/IEM_small';
import { Main } from '@gimlite/watermelon/components/main/main.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import LogoIEMAdmin from './assets/logo/iem-admin';

export const MainUse = observer(() => {
  const { SSOStore } = useRootStore();
  const navigate = useNavigate();
  const { t, lang } = useTranslation();
  const me = SSOStore.user;

  useEffect(() => {
    translationService.send('I18N', {
      operationName: 'i18n',
      operationType: 'QUERY',
      params: { key: 'bff-admin' },
    });
    translationService.send('SELECTED_LANG', { lang });
  }, []);

  useEffect(() => {
    if (me) {
      //! Je laisse les clés en commentaire
      //! >> révèle un bug de double séléction en mode drop
      //! >> en attente de validation type menu team embarquée
      navigationService.send('REGISTER_MENU', {
        menu: [
          {
            key: '/onstreet',
            position: 'top',
            title: 'Presto One',
            items: [
              {
                key: '/onstreet/sites',
                label: 'Sites',
                icon: 'faMagnifyingGlassSolid',
              },
              {
                key: '/adm/service-cards',
                label: 'Service Cards',
                icon: 'faIdCardSolid',
              },
              {
                key: '/adm/buckets',
                label: 'Buckets',
                icon: 'faBucketSolid',
                items: [
                  {
                    key: '/adm/buckets',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                  {
                    key: '/adm/fragments',
                    label: 'Fragments',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
            ],
          },
          {
            key: '/onstreet',
            position: 'top',
            title: 'Voirie',
            items: [
              {
                key: '/onstreet/clients',
                label: 'Clients',
                icon: 'faUserTieSolid',
                items: [
                  {
                    key: '/onstreet/clients',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                  {
                    key: '/onstreet/sites',
                    label: 'Sites',
                    icon: 'faMagnifyingGlassSolid',
                  },
                  {
                    key: '/onstreet/ups',
                    label: 'UPS',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
              {
                key: '/onstreet/parking-rights',
                label: 'Tickets',
                icon: 'faTicketSolid',
              },
            ],
          },
          {
            key: '/offstreet',
            position: 'top',
            title: 'Ouvrage',
            items: [
              {
                key: '/offstreet/operators',
                label: 'Opérateurs',
                icon: 'faUserNurseSolid',
                items: [
                  {
                    key: '/offstreet/operators',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                  {
                    key: '/offstreet/parkings',
                    label: 'Parkings',
                    icon: 'faMagnifyingGlassSolid',
                  },
                  {
                    key: '/offstreet/sessions',
                    label: 'Sessions',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
            ],
          },
          {
            key: '/prestoscan',
            position: 'top',
            title: 'Presto Scan',
            items: [
              {
                key: '/prestoscan/users',
                label: 'Agents',
                icon: 'faUserSecretSolid',
                items: [
                  {
                    key: '/prestoscan/users',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
              {
                key: '/prestoscan/cameras',
                label: 'Caméras',
                icon: 'faCameraSolid',
                items: [
                  {
                    key: '/prestoscan/cameras',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
            ],
          },
          {
            key: '/payments',
            position: 'top',
            title: 'Finances',
            items: [
              {
                key: '/payments/contracts',
                label: 'Contrats',
                icon: 'faAddressCardSolid',
                items: [
                  {
                    key: '/payments/contracts',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
              {
                key: '/payments/transactions',
                label: 'Transactions',
                icon: 'faCoinsSolid',
                items: [
                  {
                    key: '/payments/transactions',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
            ],
          },
          {
            key: '/adm',
            position: 'top',
            title: 'Admin',
            items: [
              {
                key: '/adm/translations',
                label: 'Traductions',
                icon: 'faLanguageSolid',
                items: [
                  {
                    key: '/adm/translations',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
              {
                key: '/mails',
                label: 'Hermes',
                icon: 'faDoveSolid',
                items: [
                  {
                    key: '/mails',
                    label: 'Mails',
                    icon: 'faMagnifyingGlassSolid',
                  },
                  {
                    key: '/sms',
                    label: 'SMS',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },

              {
                key: '/adm/admin-users',
                label: 'Users',
                icon: 'faUserSolid',
                items: [
                  {
                    key: '/adm/admin-users',
                    label: 'Recherche',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },

              {
                key: '/adm/monitoring',
                label: 'Monitoring',
                icon: 'faTerminalSolid',
                items: [
                  {
                    key: '/adm/monitoring',
                    label: 'Monitoring',
                    icon: 'faMagnifyingGlassSolid',
                  },
                ],
              },
            ],
          },
        ],
      });
    }
  }, [me, lang]);

  return (
    <Main
      handleEvent={{
        selected: (key: string) => {
          navigate(key);
        },
      }}
      config={{
        navigation: {
          logo: LogoIEMAdmin(),
          mode: 'drop',
        },
        header: {
          logo: IEM(),
          shortcut: [
            {
              label: 'Inscription a PrestoScan',
              event: () => {
                wizardService.send('SHOW_WIZARD', { id: 'createPrestoScan' });
              },
            },
            {
              label: `Création d'un Technicien PMS`,
              event: () => {
                wizardService.send('SHOW_WIZARD', {
                  id: 'createPmsTechnicalUser',
                });
              },
            },
            {
              label: "Création d'un administrateur",
              event: () => {
                wizardService.send('SHOW_WIZARD', { id: 'createAdminUser' });
              },
            },
            // {
            //   label: 'Création un utilisateur',
            //   event: () => {
            //     wizardService.send('SHOW_WIZARD', { id: 'createUser' });
            //   },
            // }, //! PAS FINI
          ],
        },
      }}
    />
  );
});

export default MainUse;
